<template>
    <zw-sidebar @shown="shown" :title="$t('common.media.label.attachments')">
        <div v-if="payload.type=='server'" class="accordion" role="tablist">
            <b-card no-body class="m-0">
                <template v-for="(data,key) in result">
                    <template v-if="checkAllowed(key)">
                        <b-card-header header-tag="header"
                                       class="p-0"
                                       role="tab"
                                       :key="'header_'+key"
                        >
                            <b-button block v-b-toggle="'accordion-'+key"
                                      class="text-left"
                                      variant="info"
                            >
                                {{ $t('common.media.types.' + key) }} ({{ data.length }})
                                <b-button class="ml-4"
                                          v-if="createTypes.includes(key)"
                                          variant="success"
                                          @click="createAttach(key)"
                                >
                                    {{ $t('common.button.create') }}
                                </b-button>
                            </b-button>
                        </b-card-header>

                        <b-collapse :id="'accordion-'+key"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                    v-if="data.length"
                        >
                            <b-card-body>
                                <b-row>
                                    <b-col cols="4" v-for="fileData in data" :key="'file'+fileData.id">
                                        <div class="attach-file">
                                            <font-awesome-icon v-if="fileIcons[fileData.name.split('.').pop()]"
                                                               :icon="fileIcons[fileData.name.split('.').pop()]"
                                                               class="ml-2 mr-2"
                                            ></font-awesome-icon>
                                            <a target="_blank"
                                               :href="mediaUrl(fileData.id,key)"
                                               v-if="['attachment','offering_attachment','article','article_attachment','position'].includes(key)"
                                            >{{ fileData.name.replace(/^.*[\\\/]/, '') }}</a>
                                            <a v-else-if="key=='contract'"
                                               @click.prevent="$root.openDocument(fileData.name, 'customer_contracts')"
                                               :href="$root.getDocumentUrl(fileData.name, 'customer_contracts')"
                                               :data-type="key"
                                            >{{ fileData.name }}</a>
                                            <a v-else
                                               @click.prevent="$root.openDocument(fileData.name, key=='simple' ? 'simple':'invoices')"
                                               :href="$root.getDocumentUrl(fileData.name, key=='simple' ? 'simple':'invoices')"
                                            >{{ fileData.name }}</a>

                                            <b-button v-if="!appendedFiles.includes(fileData.id+';'+key)"
                                                      style="position:absolute;right: 25px"
                                                      @click="append(fileData, key)"
                                                      class="ml-2"
                                                      variant="info"
                                                      size="sm"
                                            >
                                                <font-awesome-icon icon="plus"/>
                                            </b-button>
                                            <b-button v-else
                                                      style="position:absolute;right: 25px"
                                                      variant="success"
                                                      size="sm"
                                            >
                                                <font-awesome-icon icon="check"/>
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </template>
                </template>
            </b-card>
        </div>
        <div v-else>
            <zw-media v-model="media"
                      type="offering_attachment"
                      :parent_id="payload.offering_id"
                      v-if="payload.offering_id"
                      :cols="3"
                      rename
            ></zw-media>
            <zw-media v-model="media"
                      type="attachment"
                      :parent_id="payload.customer_id"
                      v-else-if="payload.customer_id"
                      :cols="3"
                      rename
            ></zw-media>
            <zw-media v-model="media"
                      type="attachment"
                      :parent_id="payload.parent_id"
                      v-else
                      :cols="3"
                      rename
            ></zw-media>
            <b-row>
                <b-col sm="12" class="text-sm-right">
                    <b-button block @click="onSubmit()" variant="primary">
                        {{ $t('common.media.button.upload') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </zw-sidebar>
</template>

<script>
export default {
    name: 'AttachmentsModal',
    data() {
        return {
            payload: {},
            callback: null,
            result: {},
            media: null,
            createTypes: [
                'offering_attachment',
                'document',
                'attachment',
                'contract',
            ],
            allowedTypes: {
                'salesAttachment': [
                    'attachment',
                    'article'
                ],
            },
            modalType: 'email',
            appendedFiles: [],
            fileIcons: {
                'pdf': 'file-pdf',
                'docx': 'file-word',
                'doc': 'file-word',
                'csv': 'file-csv',
                'png': 'file-image',
                'jpg': 'file-image',
                'gif': 'file-image',
                'jpeg': 'file-image',
                'webp': 'file-image',
                'txt': 'file-lines',
            }
        }
    },
    methods: {
        checkAllowed(key) {
            return !this.allowedTypes[this.modalType] || this.allowedTypes[this.modalType].includes(key);
        },
        shown() {
            if (this.payload.type == 'server') {
                window.axios.post(window.apiUrl + '/media/attachments', {
                    'offering_id': this.payload.offering_id,
                    'customer_id': this.payload.customer_id
                })
                    .then(response => {
                        this.result = response.data
                    })
            }
            this.modalType = this.payload.modalType
        },
        createAttach(key) {
            if (key == 'attachment') {
                this.$root.$children[0].openModal('sales-attachment-modal', {id: 0}, this.shown)
            }
            if (key == 'document') {
                this.$root.$children[0].openModal('customer-document-modal', {customerId: this.payload.customer_id}, this.refreshTable)
            }
            if (key == 'contract') {
                this.$root.$children[0].openModal('customer-contract-modal', {customerId: this.payload.customer_id}, this.shown)
            }
            if (key == 'offering_attachment') {
                this.$root.$children[0].openModal('sales-attachment-modal', {id: this.payload.offering_id}, this.shown)
            }
        },
        append(fileData, type) {
            this.callback({...fileData, type: type})
            this.appendedFiles.push(fileData.id + ';' + type)
        },
        mediaUrl(id, type) {
            const routeData = this.$router.resolve({
                name: 'Open',
                params: {type: 'media', name: id, media_type: type}
            });
            return routeData.href
        },
        onSubmit() {
            this.media.forEach(media => {
                this.append({
                    id: media.id,
                    name: media.name,
                }, media.type)
            })
            this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
        }
    },
}
</script>

<style>
.attach-file {
    background-color: lightgray;
    line-height: 30px;
    border-radius: 3px;
    padding: 3px;
    color: gray;
    margin: 3px;
}
</style>